<!-- 我的卡券-->
<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <div class="movie_tab-wrap fill-box" v-infinite-scroll="loadMore" infinite-scroll-disabled="loading" infinite-scroll-distance="0">
                    <van-empty v-if="couponList.length < 1" :image="require('@/assets/images/user/user_card_empty.png')" style="transform: translateY(50%)">
                        <div class="empty-content">
                            <div class="fc-tips">暂无可用优惠券</div>
                            <div>转发自己的<span class="fc-link" @click="goShare">二维码</span>给朋友</div>
                            <div>有机会得到更多优惠券</div>
                        </div>
                    </van-empty>
                    <div v-else style="padding: 20px 10px;">
                        <!-- <user-card v-for="item, index in couponList" :key="index" :card="item" @click-info="showInfoDialog"></user-card> -->
                        <coupon-info v-for="item, index in couponList" :key="index" :couponInfo="item"></coupon-info>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-wrap">
            <van-button block @click="showAdd = true" round>
                <template #icon>
                    <van-icon name="add-o" :color="$config.style.primaryColor"></van-icon>
                </template>
                <span>添加优惠券</span>
            </van-button>
        </div>
        <div class="utils-wrap">
            <user-card-dialog v-model="showAdd" @confirm="confirmAdd"></user-card-dialog>
            <card-info-dialog v-model="showInfo" :card="cardInfo" :name="cardName"></card-info-dialog>
        </div>
    </div>
</template>

<script>
// import UserCard from '@/components/user/user-card';
import CouponInfo from '@/components/user/user-coupon-info';
import UserCardDialog from '@/components/user/user-card-dialog';
import CardInfoDialog from '@/components/user/card-info-dialog';
import { mapGetters } from 'vuex'
import { getCouponList, queryCoupon, bindCoupon } from '@api/coupon-request';
import pagination from '@/components/mixins/pagination';
import { sortBy } from 'lodash'

export default {
    mixins: [pagination],
    data() {
        return {
            activeIndex: 0,
            couponList: [],
            exchangeCardList: [],
            showAdd: false,
            showInfo: false,
            cardInfo: null,
            cardName: null,
        }
    },
    computed: {
        ...mapGetters([
            'appKey'
        ]),
        showCoupon() {
            return this.appKey != 'ykdyp134' ? true : false;
        },
        couponListTitle() {
            let typeName = '优惠券';
            if (this.appKey == 'ykdyp134') {
                typeName = '兑换券'
            }
            return this.couponList.length > 0 ? `${typeName}(${this.couponList.length})` : typeName
        },
        exchangeCardListTitle() {
            return this.exchangeCardList.length > 0 ? `兑换券(${this.exchangeCardList.length})` : '兑换券';
        }
    },
    methods: {
        showInfoDialog(card, cardName) {
            this.cardName = cardName;
            this.$service.getCouponInfo({ cardno: card.cardno }).then((res) => {
                this.cardInfo = res;
                this.showInfo = true;
            });
        },
        // //获取兑换券
        // getDCouponList() {
        //     return new Promise((resolve) => {
        //         let params = {
        //             type: 'D',
        //             page_no: this.pagination.current_page,
        //             per: 20,
        //         }
        //         this.$service.getMemberCouponList(params).then((res) => {
        //             this.pagination = res.pagination;
        //             this.exchangeCardList = this.exchangeCardList.concat(res.couponlist);
        //             this.exchangeCardList.sort((last) => last.status == 'L' ? 1 : -1);
        //             resolve();
        //         });
        //     })
        // },
        //获取优惠券
        getACouponList() {
            return new Promise((resolve) => {
                let params = {
                    pageNo: this.pagination.pageNo,
                    pageSize: 20,
                }
                getCouponList(params).then((res) => {
                    this.copyPagination(res);
                    // this.couponList = this.couponList.concat(res.records);
                    // this.couponList.sort((last) => last.status == 'L' ? 1 : -1);
                    this.couponList = sortBy(this.couponList.concat(res.records), ['expired', 'endDate']);
                    // this.couponList.sort('bindTime');
                    resolve();
                });
            })
        },
        loadMore() {
            if (this.pagination.next) {
                ++this.pagination.current_page;
                if (this.activeIndex == 0) {
                    this.getACouponList();
                } else if (this.activeIndex == 1) {
                    this.getDCouponList();
                }
            }
        },
        confirmAdd(val) {
            this.$goPage('user-coupon-bind', {
                couponPass: val
            })
            // queryCoupon({couponPass: val }).then((res) => {
            //     console.log(res)
            // })
            return;
            let loading = this.$toast.loading('绑定中');
            let params = {
                cardpass: val,
                showtype: 'Y'
            }
            this.$service.bindCoupon(params).then((res) => {
                let type = res.type;
                loading.clear();
                this.showAdd = false;
                if (type === 'VIP') {
                    this.$goPage('user-vip');
                    return;
                }
                this.initPage(type);
            });
        },
        initPage(type) {
            this.pagination = this.$config.getDefaultPagination();
            this.couponList = [];
            this.exchangeCardList = [];
            let loading = this.$toast.loading(this.$config.loadingMsg);
            Promise.all([this.getACouponList()]).then((res) => {
                loading.clear();
            });
        },
        goShare() {
            this.$goPage('user-share')
        }
    },
    mounted() {
        this.initPage();
    },
    components: {
        CouponInfo,
        UserCardDialog,
        CardInfoDialog
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	height: 0px;
	overflow: hidden;
	/deep/ .van-tabs__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		.van-tab__pane {
			flex: 1;
			position: relative;
			.movie_tab-wrap {
				padding: 0 7px;
			}
		}
	}
}

.footer-wrap {
	padding: 20px;
	width: 100%;
	background-color: #fff;
}
.utils-wrap {
}


.empty-content {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    row-gap: 10px;
}
.fc-link {
	text-decoration: underline;
}
</style>
