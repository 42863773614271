<template>
    <van-dialog v-model="showTemp" class-name="card_info-dialog fx-column" :show-confirm-button="false">
        <template #title>
            <div class="card_info-title fx-center-middle">
                <span>卡券说明</span>
                <div class="card_info-close fx-middle" @click="close">
                    <van-icon name="cross" size="20"></van-icon>
                </div>
            </div>
        </template>
        <div class="card_info-body fx-fill">
            <div class="card_info--name">{{name}}</div>
            <div class="card_info--valid fc-tips">有效期{{$momentFormatter(cardInfo.starttime, 'YYYY-MM-DD')}}至{{$momentFormatter(cardInfo.endtime, 'YYYY-MM-DD')}}</div>
            <div class="card_info--remark fc-tips">
                <!-- <p>版本: {{cardInfo.edition}}</p> -->
                <p v-html="cardInfo.instruction"></p>
            </div>
        </div>
        <div class="card_info-footer">
            <van-button block style="color: #999999;" @click="$emit('update', false)">我知道了</van-button>
        </div>
    </van-dialog>
</template>

<script>
export default {
    model: {
        prop: 'show',
        event: 'update'
    },
    props: {
        show: Boolean,
        card: Object,
        name: String,
    },
    computed: {
        'showTemp': {
            get() {
                return this.show;
            },
            set(val) {
                this.$emit('update', val);
            }
        },
        cardInfo() {
            return this.card ? this.card : {};
        }
    },
    watch: {
        'card': {
            immediate: true,
            handler() {
                if (this.card && this.card.instruction) {
                    this.card.instruction = this.card.instruction.replace(RegExp('\\r\\n', 'g'), '<br/>')
                }
            }
        }
    },
    methods: {
        close() {
            this.$emit('update', false);
        }
    },
}
</script>

<style lang="scss" scoped>
.card_info-dialog {
	border-radius: 4px;
	/deep/ .van-dialog__header {
		padding: 0;
	}
	.card_info-title {
		height: 45px;
		background-color: $primary-color;
		color: #fff;
	}
	.card_info-close {
		position: absolute;
		right: 5px;
	}

	.card_info-body {
		padding: 20px;
		.card_info--name {
			font-weight: 700;
            padding: 2px 0;
		}
		.fc-tips {
			font-size: 12px;
		}
	}
}
</style>